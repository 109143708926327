import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BackendUrls {
    loginUrl = environment.apiUrl + '/login';
    forgotPasswordUrl = environment.apiUrl + '/password/forgot';
    setPasswordUrl = environment.apiUrl + '/password/set-password';
    customerUrl = environment.apiUrl + '/customer';
    productUrl = environment.apiUrl + '/product';
    productUniqueTitlesUrl = environment.apiUrl + '/product/unique';
    quotationUrl = environment.apiUrl + '/quotation';
    quotationDownloadExcelUrl = environment.apiUrl + '/quotation/generate-excel';
    tncUrl = environment.apiUrl + '/tnc';
    quotationSeriesUrl = environment.apiUrl + '/quotationId';
    constructor() {}

}
