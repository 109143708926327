import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BackendUrls} from './url';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient, private toastr: ToastrService, public backendUrls: BackendUrls) {
    }

    /**
     * Auth Component
     * */
    onConfirmLogin(LoginData) {
        return this.http.post(this.backendUrls.loginUrl, LoginData).pipe(catchError(error => this.handleError(error)));
    }

    onForgotPassword(forgotPasswordData) {
        return this.http.post(this.backendUrls.forgotPasswordUrl, forgotPasswordData).pipe(catchError(error => this.handleError(error)));
    }

    onSetPassword(setPasswordData) {
        return this.http.post(this.backendUrls.setPasswordUrl, setPasswordData).pipe(catchError(error => this.handleError(error)));
    }

    /**
     * Customer Component
     * */
    onCreateCustomer(customerData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.post(this.backendUrls.customerUrl, customerData, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onListCustomer() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.get(this.backendUrls.customerUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }

   onListProductTitles() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.get(this.backendUrls.productUniqueTitlesUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onGetCustomerById(customerId) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.get(this.backendUrls.customerUrl + '/' + customerId, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onUpdateCustomer(customerData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.put(this.backendUrls.customerUrl, customerData, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onDeleteCustomer(customerId) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.delete(this.backendUrls.customerUrl + '/' + customerId, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    /**
     * Product Component
     * */
    onListProduct() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.get(this.backendUrls.productUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    getProductByName(productName) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.post(this.backendUrls.productUrl + '/productName', {productName}, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    /**
     * Quotation Component
     * */
    onCreateQuotation(quotationData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.post(this.backendUrls.quotationUrl, quotationData, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onListQuotation() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.get(this.backendUrls.quotationUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }
    onGetQuotationById(quotationId) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.get(this.backendUrls.quotationUrl + '/' + quotationId, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onUpdateQuotation(quotationData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.put(this.backendUrls.quotationUrl, quotationData, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    onDeleteQuotation(quotationId) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType', '*/*');
        return this.http.delete(this.backendUrls.quotationUrl + '/' + quotationId, {headers}).pipe(catchError(error => this.handleError(error)));
    }
    onDownloadQuotation(quotationData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('responseType' , '*/*');
        // option['responseType'] = 'blob';

        return this.http.post(this.backendUrls.quotationDownloadExcelUrl, quotationData, {headers, responseType: 'blob'}).pipe(catchError(error => this.handleError(error)));
    }
   onListTnc() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.get(this.backendUrls.tncUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }
   onAddTnc(tnc) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.post(this.backendUrls.tncUrl, tnc, {headers}).pipe(catchError(error => this.handleError(error)));
    }
   onListQuotationSeries() {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.get(this.backendUrls.quotationSeriesUrl, {headers}).pipe(catchError(error => this.handleError(error)));
    }
   onUpdateQuotationSeries(quotationData) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.post(this.backendUrls.quotationSeriesUrl, quotationData, {headers}).pipe(catchError(error => this.handleError(error)));
    }
   onDeleteTnc(tncId) {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        return this.http.delete(this.backendUrls.tncUrl + '/' + tncId, {headers}).pipe(catchError(error => this.handleError(error)));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(this.toastr.error(error.error.message));
    }
}
