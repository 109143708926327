import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppService} from '../app.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    test: Date = new Date();
    forgotPasswordForm: FormGroup;
    isValid: boolean;

    constructor(private router: Router, private appService: AppService) {
    }

    ngOnInit(): void {
        this.isValid = true;
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl(null, [Validators.email, Validators.required]),
        });
    }
    forgotPassword() {
        if (!this.checkFormValid()) {
            return;
        }
        const forgotPasswordData = {
            email: this.forgotPasswordForm.value.email,
        };
        this.appService.onForgotPassword(forgotPasswordData).subscribe(response => {
            if (response['status']) {

            }
        });
    }

    checkFormValid() {
        if (this.forgotPasswordForm.valid) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
        return this.isValid;
    }

    get e() {
        return this.forgotPasswordForm.controls;
    }

}
