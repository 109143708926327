import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule, CanActivate} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './login/login.component';
import {AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import {LoginGuardService as LoginGuard} from './auth/login-guard.service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {SetPasswordComponent} from './set-password/set-password.component';

const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        children: [{
        canActivate: [AuthGuard],
            path: '',
            loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
        }],
    }, {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard],
        children: [{
            path: 'login',
            loadChildren: './login/login.module#LoginModule'
        }]
    }, {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [LoginGuard],
        children: [{
            path: 'forgot-password',
            loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'
        }]
    }, {
        path: 'set-password/:token',
        component: SetPasswordComponent,
        children: [{
            path: 'set-password/',
            loadChildren: './set-password/set-password.module#SetPasswordModule'
        }]
    },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [],
})
export class AppRoutingModule {
}
