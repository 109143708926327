import {Component, EventEmitter, OnInit, Output} from '@angular/core';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    // {path: '/user-profile', title: 'User Profile', icon: 'person', class: ''},
    // {path: '/table-list', title: 'Table List', icon: 'content_paste', class: ''},
    // {path: '/typography', title: 'Typography', icon: 'library_books', class: ''},
    // {path: '/icons', title: 'Icons', icon: 'bubble_chart', class: ''},
    // {path: '/maps', title: 'Maps', icon: 'location_on', class: ''},
    // {path: '/notifications', title: 'Notifications', icon: 'notifications', class: ''},
    // {path: '/create-user', title: 'User', icon: 'person', class: ''},
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    public newShowMenu: string[];
    collapsed: boolean;
    @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
        this.collapsed = false;
        this.newShowMenu = ['0', '0', '0', '0'];
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    addExpandClass(element: any, position: any) {
        if (element === this.newShowMenu[position]) {
            this.newShowMenu[position] = '0';
        } else {
            for (let i = 0; i < this.newShowMenu.length; i++) {
                if (i !== element) {
                    this.newShowMenu[i] = '0';
                }
            }
            this.newShowMenu[position] = element;
            // this.collapsed = !this.collapsed;
            // if (!this.collapsed) {
            //     this.collapsedEvent.emit(this.collapsed);
            // } else {
            //     this.collapsed = !this.collapsed;
            //     this.collapsedEvent.emit(this.collapsed);
            // }
        }
    }
}
