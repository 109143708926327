import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../app.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.css', '../../styles.css']
})
export class SetPasswordComponent implements OnInit {

    test: Date = new Date();
    setPasswordForm: FormGroup;
    isValid: boolean;
    token: string;
    constructor(private router: Router, private appService: AppService, private activatedRoute: ActivatedRoute, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.token = params['token'];
        });
        this.isValid = true;
        this.setPasswordForm = new FormGroup({
            password: new FormControl(null, [Validators.required]),
            confirmPassword: new FormControl(null, [Validators.required]),
        });
    }
    setPassword() {
        if (!this.checkFormValid()) {
            return;
        }
        const setPasswordData = {
            password: this.setPasswordForm.value.password,
            token: this.token,
        };
        this.appService.onSetPassword(setPasswordData).subscribe(response => {
            if (response['status']) {
                this.router.navigate(['/login']);
                this.toastr.success('Password reset successfully.')
            }
        });
    }

    checkFormValid() {
        if (this.setPasswordForm.valid && this.setPasswordForm.value.password === this.setPasswordForm.value.confirmPassword) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
        return this.isValid;
    }

    get e() {
        return this.setPasswordForm.controls;
    }

}
