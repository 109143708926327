import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    test: Date = new Date();
    loginForm: FormGroup;
    isValid: boolean;
    rememberMe: boolean;

    constructor(private appService: AppService, private router: Router, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.isValid = true;
        this.loginForm = new FormGroup({
            email: new FormControl(null, [Validators.email, Validators.required]),
            password: new FormControl(null, [Validators.required, Validators.min(6)]),
            rememberMe: new FormControl(null)
        });

    }

    login() {
        if (!this.checkFormValid()) {
            return;
        }
        const loginData = {
            username: this.loginForm.value.email,
            password: this.loginForm.value.password
        };
        this.appService.onConfirmLogin(loginData).subscribe(response => {
            if (response['token'] && response['user']) {
                this.toastr.success('Logged in successfully.');
                if (this.loginForm.value.rememberMe) {
                    localStorage.setItem('email', response['user']['email']);
                    localStorage.setItem('firstName', response['user']['firstName']);
                    localStorage.setItem('lastName', response['user']['lastName']);
                    localStorage.setItem('phoneNumber', response['user']['phoneNumber']);
                    localStorage.setItem('token', response['token']);
                    this.router.navigate(['/dashboard']);
                } else {
                    sessionStorage.setItem('email', response['user']['email']);
                    sessionStorage.setItem('firstName', response['user']['firstName']);
                    sessionStorage.setItem('lastName', response['user']['lastName']);
                    sessionStorage.setItem('phoneNumber', response['user']['phoneNumber']);
                    sessionStorage.setItem('token', response['token']);
                    this.router.navigate(['/dashboard']);
                }
            }
        });
    }

    checkFormValid() {
        if (this.loginForm.valid) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
        return this.isValid;
    }

    get e() {
        return this.loginForm.controls;
    }

}
