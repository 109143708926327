import {Component, OnInit, ElementRef} from '@angular/core';
import {ROUTES} from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {AppService} from "../../app.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css', '../../../styles.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    tncs = [];
    quotationSeries: any;
    newTnc: string;

    constructor(location: Location, private element: ElementRef, private router: Router, private confirmDialog: MatDialog, private appService: AppService, private toastr: ToastrService) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
        this.listTncs();
        this.listQuotationSeries();
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function () {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                $layer.classList.remove('visible');
                setTimeout(function () {
                    $layer.remove();
                    $toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }

    logout() {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    addTncDialogue(tncAddPrompt) {
        const dialogRef = this.confirmDialog.open(DialogComponent, {
            width: '750px',
            data: {
                template: tncAddPrompt
            }
        });
        dialogRef.afterClosed().subscribe(dataFromConfirm => {
        });

    }

    listTncs() {
        this.appService.onListTnc().subscribe((response: any) => {
            this.tncs = response;
        })
    }
    listQuotationSeries() {
        this.appService.onListQuotationSeries().subscribe((response: any) => {
            this.quotationSeries = response;
        })
    }

    addTnc() {
        if (this.newTnc) {
            this.appService.onAddTnc({
                description: this.newTnc.trim()
            }).subscribe(response => {
                this.toastr.success('Term added successfully.');
                this.newTnc = '';
                this.listTncs();
            })
        }
    }

    deleteTnc(tncId) {
        for(let i = 0; i < this.tncs.length; i++) {
            if(tncId === this.tncs[i].id){
                this.tncs.splice(i, 1);
                break;
            }
        }
        this.appService.onDeleteTnc(tncId).subscribe(response => {
            this.toastr.success('Term deleted successfully.')
        })
    }

    updateQuotationIdDialogue(quotationSeriesPrompt) {
        const dialogRef = this.confirmDialog.open(DialogComponent, {
            width: '400px',
            data: {
                template: quotationSeriesPrompt
            }
        });
        dialogRef.afterClosed().subscribe(dataFromConfirm => {
            if (dataFromConfirm && dataFromConfirm.confirm) {
                this.appService.onUpdateQuotationSeries(this.quotationSeries).subscribe(response => {
                    this.toastr.success('Quotation Series updated successfully.');
                })
            }
        });
    }
}
